<template>
  <v-container>
    <v-row class="justify-center">
      <v-card class="ma-5" width="900">
        <v-card-text>
          <privacy-statement />
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import PrivacyStatement from "@/components/legal/PrivacyStatement";
export default {
  components: { PrivacyStatement }
};
</script>
