<template>
  <v-row class="justify-center">
    <v-card width="900" class="ma-5">
      <v-card-text>
        <terms-statement />
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import TermsStatement from "@/components/legal/TermsStatement";
export default {
  name: "terms",
  components: { TermsStatement }
};
</script>

<style scoped></style>
