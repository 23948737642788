<template>
  <v-row class="justify-center">
    <v-card width="900" class="ma-5">
      <v-card-text>
        <privacy-statement />
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import PrivacyStatement from "@/components/legal/PrivacyStatement";
export default {
  name: "Privacy",
  components: { PrivacyStatement }
};
</script>

<style scoped></style>
